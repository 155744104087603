import React from "react";
import './Navbar.css';

const Navbar = () => {
    return (
        <nav className="nav">
            <ul>
                <li><a href="./index.html"><b>Produced  by  MEANINGFUL MORING CHANNEL</b></a></li>
            </ul>
        </nav>
    )
}
export default Navbar;

